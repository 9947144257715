import { FunctionComponent, SVGAttributes } from 'react';
import { DataRouteObject } from 'react-router';
import monitorImage from '../../common/assets/images/monitor.png';
import ordersImage from '../../common/assets/images/ordersIcon.png';
import rolesIcon from '../../common/assets/images/roleIcon.png';
import usersImage from '../../common/assets/images/userIcon.png';
import warehouseImage2 from '../../common/assets/images/warehouse.png';
import warehouseImage from '../../common/assets/images/warehouseIcon.svg';
import AddSerial from '../addSerial/AddSerial';
import { PrimaryAppBar } from '../appBar/PrimaryAppBar';
import BatchOrderMonitor from '../batchOrderMonitor/BatchOrderMonitor';
import ClearSerial from '../clearSerial/ClearSerial';
import OldInbound from '../inbound/OldInbound';
import { LoginPage } from '../loginPage/LoginPage';
import { MainSection } from '../mainSection/MainSection';
import NotFound from '../notFound/NotFound';
import { default as OldRma } from '../oldRma/Rma';
import OrderLookup from '../orderLookup/OrderLookup';
import Printer from '../printer/Printer';
import ActiveSession from '../procedure/components/ActiveSession/ActiveSession';
import SessionStatus from '../procedure/components/ActiveSession/SessionStatus';
import Canvas from '../procedure/components/Canvas/Canvas';
import ReceiverList from '../procedure/components/receiverList/ReceiverList';
import Procedure from '../procedure/Procedure';
import ProcedureLayout from '../procedure/ProcedureLayout';
import QualityControl from '../qualityControl/QualityControl';
import RapidQc from '../rapidQc/RapidQc';
import CreateWithAsn from '../receivers/components/inbound/create/CreateWithAsn';
import ResumeId from '../receivers/components/inbound/resume/ResumeId';
import Receivers from '../receivers/Receivers';
import Rma from '../rma/Rma';
import * as permissions from '../roles/permissionsList';
import Roles from '../roles/Roles';
import SerializedBatchShipping from '../serializedBatchShipping/SerializedBatchShipping';
import SerialLocation from '../serialLocation/Serial';
import ShipMatrix from '../shipMatrix/ShipMatrix';
import SkuVelocity from '../skuVelocity/SkuVelocity';
import Tracking from '../tracking/Tracking';
import Users from '../users/Users';
import WorkOrderCreateDialog from '../workOrder/components/Form/WorkOrderCreateDialog';
import WorkOrder from '../workOrder/WorkOrder';
import { PrivateRoutes } from './PrivateRoutes';

export type RouteData = Omit<DataRouteObject, 'id' | 'children'> & {
    children?: RouteData[]; //for deep linking
    permissions?: string[]; //will deny user and not render if user does not have all of these permissions
    cardData?: CardData; //will render a card in MainSection if this is set
};

export interface CardData {
    title: string;
    description: string;
    image: HTMLImageElement | FunctionComponent<SVGAttributes<SVGElement>>;
}

export const AUTH_ROUTE_DATAS: RouteData[] = [
    {
        path: '/',
        element: <PrivateRoutes />,
        children: [
            {
                path: '/',
                element: <PrimaryAppBar />,
                children: [
                    {
                        path: '/',
                        element: <MainSection />,
                    },
                    {
                        path: 'receivers',
                        permissions: [permissions.RECEIVER_ACCESS, permissions.INBOUND_ACCESS],
                        cardData: {
                            title: 'Receivers',
                            description: 'Modify inventory counts',
                            image: ordersImage,
                        },
                        children: [
                            {
                                index: true,
                                element: <Receivers />,
                            },
                            {
                                path: ':receiverId',
                                element: <ResumeId />,
                            },
                            {
                                path: ':momCode',
                                children: [
                                    {
                                        path: ':poReferenceNumber',
                                        element: <CreateWithAsn />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'inbound',
                        element: <OldInbound />,
                        permissions: [permissions.INBOUND_ACCESS],
                        cardData: {
                            title: 'Inbound',
                            description: 'Process inbound shipment',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'printer',
                        element: <Printer />,
                        permissions: [permissions.PRINTER_ACCESS],
                        cardData: {
                            title: 'Printer',
                            description: 'Print stuff',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'addSerial',
                        element: <AddSerial />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Add Serials',
                            description: 'Add Serials',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'oldRma',
                        element: <OldRma />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Old Rma',
                            description: 'Old Rma Scanning',
                            image: ordersImage,
                        },
                    },
                    {
                        path: 'Rma',
                        element: <Rma />,
                        permissions: [permissions.RMA_ACCESS],
                        cardData: {
                            title: 'Rma',
                            description: 'Rma Scanning',
                            image: ordersImage,
                        },
                    },
                    {
                        path: 'workOrder',
                        permissions: [permissions.ACCESS_WORK_ORDER],
                        cardData: {
                            title: 'Work Order',
                            description: 'Manage Work Orders',
                            image: monitorImage,
                        },
                        children: [
                            {
                                index: true,
                                element: <WorkOrder />,
                            },
                            {
                                path: ':workOrderId',
                                element: <WorkOrderCreateDialog />,
                            },
                        ],
                    },
                    {
                        path: 'serialLocation',
                        element: <SerialLocation />,
                        permissions: [permissions.SERIAL_LOCATION_ACCESS],
                        cardData: {
                            title: 'Serial Location',
                            description: 'FRS serial locations',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'shipMatrix',
                        element: <ShipMatrix />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Ship Matrix',
                            description: 'Ship Matrix',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'qualityControl',
                        element: <QualityControl />,
                        permissions: [permissions.QUALITY_CONTROL_USER_ACTIONS],
                        cardData: {
                            title: 'Quality Control',
                            description: 'Perform QC',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'serializedBatchShipping',
                        element: <SerializedBatchShipping />,
                        permissions: [permissions.SERIALIZED_BATCH_SHIPPING_ACCESS],
                        cardData: {
                            title: 'Serialized Batch Shipping',
                            description: 'Intended for GBM',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'clearSerial',
                        element: <ClearSerial />,
                        permissions: [permissions.CLEAR_SERIAL],
                        cardData: {
                            title: 'Clear Serial',
                            description: 'Clear Serials',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'skuVelocity',
                        element: <SkuVelocity />,
                        permissions: [permissions.ACCESS_SKU_VELOCITY],
                        cardData: {
                            title: 'Sku Velocity',
                            description: 'View Sku Velocity',
                            image: warehouseImage,
                        },
                    },
                    {
                        path: 'tracking',
                        element: <Tracking />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Tracking',
                            description: 'Tracking',
                            image: monitorImage,
                        },
                    },
                    {
                        path: 'orderLookup',
                        permissions: [permissions.ORDER_LOOKUP_ACCESS],
                        cardData: {
                            title: 'Order Lookup',
                            description: 'Lookup an order',
                            image: monitorImage,
                        },
                        children: [
                            {
                                index: true,
                                element: <OrderLookup />,
                            },
                            {
                                path: ':momCode',
                                children: [
                                    {
                                        path: ':orderId',
                                        element: <OrderLookup />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'rapidQc',
                        element: <RapidQc />,
                        permissions: [permissions.QUALITY_CONTROL_USER_ACTIONS],
                        cardData: {
                            title: 'Rapid QC',
                            description: 'Lot Number Processing',
                            image: warehouseImage2,
                        },
                    },
                    {
                        path: 'swrop',
                        permissions: [permissions.PROCEDURE_ACCESS],
                        element: <ProcedureLayout />,
                        cardData: {
                            title: 'SWROP',
                            description: 'Standard Work Rush Order/Operating Procedure',
                            image: warehouseImage,
                        },
                        children: [
                            {
                                index: true,
                                element: <Procedure shouldRenderCreateButton={true} />,
                            },
                            {
                                path: ':WorkOrderProcedureId',
                                element: <SessionStatus />,
                            },
                            {
                                path: 'preview',
                                children: [
                                    {
                                        path: ':procedureId',
                                        element: <ActiveSession />,
                                    },
                                ],
                            },
                            {
                                path: 'canvas',
                                children: [
                                    {
                                        path: ':procedureId',
                                        element: <Canvas />,
                                    },
                                ],
                            },
                            {
                                path: 'receiver',
                                element: <ReceiverList />,
                            },
                        ],
                    },
                    {
                        path: 'roles',
                        element: <Roles />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Roles',
                            description: 'Roles',
                            image: rolesIcon,
                        },
                    },
                    {
                        path: 'users',
                        element: <Users />,
                        permissions: [permissions.ROLE_CREATE],
                        cardData: {
                            title: 'Users',
                            description: 'Users',
                            image: usersImage,
                        },
                    },
                    {
                        path: 'batchOrderMonitor',
                        element: <BatchOrderMonitor />,
                        permissions: [permissions.BATCH_ORDER_MONITOR_ACCESS],
                        cardData: {
                            title: 'Serialized Batch Order Monitor',
                            description: 'Tracks Batch Order Statuses',
                            image: monitorImage,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

const recusivelyGetRoutesWithCard = (data: RouteData): RouteData[] => {
    if (data?.cardData) return [data]; //gets top most card
    if (!data?.children?.length) return [];

    var result: RouteData[] = [];
    data.children?.forEach(child => {
        result.push(...recusivelyGetRoutesWithCard(child));
    });
    return result;
};

export const ROUTES_WITH_CARDS = recusivelyGetRoutesWithCard(AUTH_ROUTE_DATAS[0]);

const doesRouteSatisfyPermissions = (data: RouteData, permissions: string[]): boolean => {
    if (!data?.permissions?.length) return true;

    return data?.permissions.every(requiredPermission => permissions.includes(requiredPermission));
};

const filterPermittedChildren = (data: RouteData, permissions: string[]): RouteData => {
    const currentParent = { ...data };
    currentParent.children =
        currentParent?.children?.filter(child => doesRouteSatisfyPermissions(child, permissions)) || [];
    return currentParent;
};

const recursivelyGetOnlyPermittedChildren = (data: RouteData, permissions: string[]): RouteData => {
    if (!data?.children?.length) return { ...data };

    const cleanedData = filterPermittedChildren(data, permissions);
    return {
        ...cleanedData,
        children: cleanedData?.children?.map(child => recursivelyGetOnlyPermittedChildren(child, permissions)) || [],
    };
};

export const getAllowedRoutes = (permissions: string[]): RouteData[] => {
    const result: RouteData[] = AUTH_ROUTE_DATAS.map(data => {
        return recursivelyGetOnlyPermittedChildren(data, permissions);
    });

    return result;
};
