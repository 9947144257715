import { Typography } from '@mui/material';
import { useAppSelector } from '../../../../app/store';
import { hasError } from '../../utilities';

export const ProcessedRmaDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);

    if (!!rma && hasError(rma.Status) && !!rma.ProcessedOrderId) {
        return <Typography color="warning.main">RMA SUBMITTED WITH ERRORS (ID: {rma?.Id}): CONTACT ADMIN</Typography>;
    }

    if (!!rma && hasError(rma.Status)) {
        return <Typography color="error.main">ERROR PROCESSING RMA (ID: {rma?.Id}): CONTACT ADMIN</Typography>;
    }
    if (!rma?.ProcessedOrderId) {
        return <Typography color="warning.main"> NO ORDER ID CREATED: RMA HAS ALL UNKNOWN SKUS</Typography>;
    }

    return (
        <Typography fontWeight={'fontWeightMedium'} color="success.main">
            RMA PROCESSED
        </Typography>
    );
};
